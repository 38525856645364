.sp-landing {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.vi-container {
    margin: 0px auto;
    height: 50px;
    width: 200px;
}

.vi-character {
    line-height: 50px;
    font-size: 36px;
    color: #daa520;
    /* color: rgb(30, 155, 145); */
    /* color: black; */
    background-color: white;
    border: 1px solid transparent;
    border-radius: 15px;
    margin: 5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
    text-shadow: -1px 1px 1px rgba(0, 0, 0, 1);
}

.vi-character-incorrect {
    line-height: 50px;
    font-size: 36px;
    color: #da4820;
    /* color: rgb(30, 155, 145); */
    /* color: black; */
    background-color: white;
    border: 1px solid transparent;
    border-radius: 15px;
    margin: 5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
    text-shadow: -1px 1px 1px rgba(0, 0, 0, 1);
}

.vi-character--inactive {
    background-color: rgba(255, 255, 255, 0.5);
}

.vi-character--selected {
    /* border: 1px solid rgb(30, 155, 145); */ /* Empirically selected */

    /* https://www.sessions.edu/color-calculator/ */
    /* border: 1px solid #20dac1; */ /* goldenrod triadic */
    /* border: 1px solid #89da20; */ /* goldenrod tetradic */

    /* https://www.canva.com/colors/color-wheel/ */
    border: 1px solid rgba(32, 218, 165, 0.8);
    /* border: 1px solid #20DA48; */ /* goldenrod (#daa520) tetradic */
}

.logo {
    position: absolute;
    width: 50%;
    height: 20%;
    top: 25%;
    left: 25%;
}

.input-container {
    margin-top: 5%;
}